import React from 'react';
import axios from 'axios';
import i18n from 'i18next';
import moment from 'moment-timezone';
import CryptoJS from 'crypto-js';
import i18nNation from 'i18n-nationality';
import { Icon } from '@iconify/react';
import {
  DEFAULT_DOCTOR_RATE,
  PAY_DOCTOR_RATE,
  SECRET_KEY,
  DAZ_CODE
} from '../configs';
import { getHospitalByCode } from '../actions/HospitalAction';
import { getCompanyByCode } from '../actions';

i18nNation.registerLocale(require('i18n-nationality/langs/en.json'));
i18nNation.registerLocale(require('../locales/th/nationalities.json'));

export const getPatientImageBase64 = patCode => new Promise((resolve, reject) => {
  axios.get(`files/base64/byPatCode/${patCode}`).then(response => {
    resolve((response?.data?.fileBase64 !== 'null' && response?.data?.fileBase64) || '');
  }).catch(() => {
    reject();
  });
});

export const getDoctorProfileImageBase64 = docCode => new Promise((resolve, reject) => {
  axios.get(`files/base64/byDocCode/${docCode}`).then(response => {
    resolve((response?.data?.fileBase64 !== 'null' && response?.data?.fileBase64) || '');
  }).catch(() => {
    reject();
  });
});

export const getFileUrl = file => new Promise((resolve, reject) => {
  axios.post('files/url', file).then(response => {
    resolve((response?.data?.fileUrl !== 'null' && response?.data?.fileUrl) || '');
  }).catch(() => {
    reject();
  });
});

export const getFileBase64 = file => new Promise((resolve, reject) => {
  axios.post('files/base64', file).then(response => {
    resolve((response?.data?.fileBase64 !== 'null' && response?.data?.fileBase64) || '');
  }).catch(() => {
    reject();
  });
});

export const uploadFileBase64 = file => new Promise((resolve, reject) => {
  axios.post('files/upload', { file }).then(response => {
    resolve((response?.data?.data !== 'null' && response?.data?.data) || (response?.data !== 'null' && response?.data) || {});
  }).catch(() => {
    reject();
  });
});

export const getAgeByBirthDate = datetime => {
  if (!datetime || datetime === '' || datetime === 'null' || !moment(new Date(datetime.toString())).isValid()) {
    return { years: 0, months: 0 };
  }
  const years = moment().diff(moment(datetime), 'years');
  const months = moment().diff(moment(datetime), 'months') - (years * 12);
  return { years, months };
};

export const getAges = (datetime, t) => {
  const { years, months } = getAgeByBirthDate(datetime);
  return `${years} ${t('years')} ${months} ${t('months')}`;
};

export const getDatetimeLocal = (datetime, format = 'D MMMM YYYY HH:mm', useUTC = false, isConverYearTh = true) => {
  if (!datetime || datetime === '' || datetime === 'null' || !moment(new Date(datetime.toString())).isValid()) {
    return '-';
  }
  const timezone = localStorage.getItem('timezone') || moment.tz.guess();
  const result = useUTC ? moment.utc(datetime).tz(timezone) : moment(datetime);
  if (i18n.language === 'th' && isConverYearTh) {
    const convertYear = parseInt(result.format('YYYY')) + 543;
    return result.format(format).replace(result.format('YYYY'), convertYear);
  }
  return result.format(format);
};

export const getDatetimeFromZero = (info, format = 'D MMMM YYYY HH:mm', isConverYearTh = true) => {
  if (info?.visit_datetime_zero && moment(info?.visit_datetime_zero).isValid()) {
    return getDatetimeLocal(info.visit_datetime_zero, format, true, isConverYearTh);
  }
  if (info?.visitDate) {
    const datetime = `${info?.visitDate} ${info?.visitTime}`;
    if (info?.visit_timezone && !['', 'null'].includes(info?.visit_timezone)) {
      return getDatetimeLocal(moment(datetime).tz(info.visit_timezone).format(), format, false, isConverYearTh);
    }
    return getDatetimeLocal(datetime, format, false, isConverYearTh);
  }
  if (info && typeof info === 'string') {
    const datetime = `${info}`;
    if (info && !['', 'null'].includes(info)) {
      return getDatetimeLocal(moment(datetime).tz(info).format(), format, false, isConverYearTh);
    }
    return getDatetimeLocal(datetime, format, false, isConverYearTh);
  }
  return '-';
};

export const formatCurrency = (value, decimal = 2) => {
  if (Number.isNaN(value) || typeof value === 'undefined') {
    return (0).toFixed(decimal);
  }
  return (value / 1).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const encrypt = data => {
  const message = typeof data === 'string' ? data : JSON.stringify(data);
  return CryptoJS.AES.encrypt(message, SECRET_KEY).toString();
};

export const decrypt = (ciphertext, type = 'object') => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return type === 'string' ? bytes.toString(CryptoJS.enc.Utf8) : JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return false;
  }
};

export const randomString = (value, str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
  const shuffle = string => [...string].reduceRight((res, _, __, arr) => [...res, arr.splice(~~(Math.random() * arr.length), 1)[0]], []).join('');
  return shuffle(str).substr(0, value);
};

export const formatIDCard = str => {
  if (!str) {
    return '-';
  }
  return `${str.substring(0, 1)}-${str.substring(1, 5)}-${str.substring(5, 10)}-${str.substring(10, 12)}-${str.substring(12, 13)}`;
};

export const formatPhoneNumber = phone => {
  if (!phone) {
    return '-';
  }
  if (phone.substring(0, 3) === '+66' && phone.length === 12) {
    return `${phone.substring(0, 3)} ${phone.substring(3, 5)} ${phone.substring(5, 8)} ${phone.substring(8, 12)}`;
  }
  return phone;
};

export const getProfileName = data => {
  let { firstName } = data || { firstName: '' };
  if (data?.dataLang && typeof data?.dataLang[i18n.language] !== 'undefined') {
    if (data?.dataLang[i18n.language]?.firstName !== 'null') {
      firstName = data?.dataLang[i18n.language].firstName;
    }
  }
  return `${firstName || ''}`;
};

export const getDoctorNames = data => {
  const docName = {
    th: `${data?.firstName} ${data?.lastName}`
  };
  if (data?.dataLang && Object.keys(data.dataLang).length > 0) {
    Object.keys(data.dataLang).forEach(lang => {
      if (data.dataLang[lang]?.firstName !== '' && data.dataLang[lang]?.lastName !== '') {
        docName[lang] = `${data.dataLang[lang]?.firstName} ${data.dataLang[lang]?.lastName}`;
      }
    });
  }
  return docName;
};

export const getNewCountryCode = value => {
  let code = value === 'ENG' ? 'GB' : value;
  code = code === 'EN' ? 'GB' : code;
  code = code === 'Japanese' ? 'JP' : code;
  code = code === 'CHI' ? 'CN' : code;
  return code;
};

export const getNation = data => {
  if (data?.length > 0) {
    return data.map(o => i18nNation.getName(o.code, i18n.language)).join(', ');
  }
  return '-';
};

export const getPatientNation = data => {
  if (data?.length > 0) {
    return data.map(o => i18nNation.getName(o.code, i18n.language)).join(', ');
  }
  return '-';
};

export const getCareGiverName = data => {
  let name = '-';
  if (data?.careGiver !== 'null') {
    name = `${data.careGiverFirstName !== 'null' && data.careGiverFirstName} ${data.careGiverLastName !== 'null' && data.careGiverLastName}`;
  }
  return name;
};

export const validatePassportNumber = (passportNumber, checkSum = false) => {
  const regex = /^(?:([A-Z]{1,2}[0-9]{6,9})|([A-Z]{1,2}[0-9]{1,3}[A-Z]{1,2}[0-9]{1,5})|([0-9]{1,3}[A-Z]{1,2}[0-9]{1,7})|([0-9]{9}))$/;
  const checkLogic = passportNumber.length >= 8 && passportNumber.length <= 9 && regex.test(passportNumber);
  if (!checkLogic) {
    return false;
  }

  if (checkSum) {
    const weights = [7, 3, 1];
    let sum = 0;

    for (let i = 0; i < passportNumber.length - 1; i++) {
      const char = passportNumber[i];
      let value = 0;

      if (/[0-9]/.test(char)) {
        value = parseInt(char);
      } else if (/[A-Z]/.test(char)) {
        value = char.charCodeAt(0) - 55;
      } else if (char === '<') {
        value = 0;
      }

      sum += value * weights[i % 3];
    }

    const lastChar = passportNumber[passportNumber.length - 1];
    if (!/[0-9]/.test(lastChar)) {
      return false;
    }

    const checkDigit = parseInt(lastChar);
    return (sum % 10) === checkDigit;
  }
  return true;
};

export const validateThaiID = idNumber => {
  if (idNumber.substring(0, 1) === 0) {
    return false;
  }
  if (idNumber.length !== 13) {
    return false;
  }
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseFloat(idNumber.charAt(i)) * (13 - i);
  }
  if ((11 - (sum % 11)) % 10 !== parseFloat(idNumber.charAt(12))) {
    return false;
  }
  return true;
};

export const checkIdCard = passportNumber => !validateThaiID(passportNumber) && !validatePassportNumber(passportNumber);

export const getApplicationLink = data => {
  let applicationName = data?.createByAppName;
  if (data?.useWeb && data?.shortLinkForComplete && !['', 'null'].includes(data?.shortLinkForComplete)) {
    applicationName = data?.shortLinkForComplete;
  }
  return applicationName;
};

const checkNotPassTimeBooking = item => {
  const date = moment(`${item?.visitDate} ${item?.visitTime}`, 'YYYY-MM-DD HH:mm');
  return date.isValid() && moment().isSameOrBefore(date);
};

const checkPassTimeBooking = item => {
  const date = moment(`${item?.visitDate} ${item?.visitTime}`, 'YYYY-MM-DD HH:mm');
  return date.isValid() && moment().isAfter(date);
};

export const checkToConfirmBooking = item => (item?.type !== 'online' && typeof item?.information?.confirm === 'undefined' && checkNotPassTimeBooking(item?.information));

export const checkPendingInformation = item => typeof item?.pendingInformation?.visitDate !== 'undefined';

export const checkPendingByDoctor = item => checkPendingInformation(item) && (item?.pendingInformation?.role && item?.pendingInformation?.role === 'doctor');

export const checkToCallBooking = item => ((item?.information?.confirm || checkPendingInformation(item)) || checkPassTimeBooking(item.information)) && item.status === 'paid';

export const checkToCallNow = item => item?.information?.confirmCallNow || checkPassTimeBooking(item.information);

export const secToMunite = time => `${(Math.floor(time / 60)).toString().padStart(2, '0')}:${(time % 60).toString().padStart(2, '0')}`;

export const secToHms = time => `${(Math.floor(time / 60 / 60)).toString().padStart(2, '0')}:${(Math.floor((time / 60) % 60)).toString().padStart(2, '0')}:${(time % 60).toString().padStart(2, '0')}`;

export const getFileByPatCode = patCode => new Promise((resolve, reject) => {
  axios.get(`files/url/byPatCode/${patCode}`).then(response => {
    resolve(typeof response.data.fileUrl !== 'undefined' ? response.data.fileUrl : '');
  }).catch(error => {
    reject(error);
  });
});

export const getStatusForReturnCase = data => data?.status === 'complete' || (data?.status === 'cancel' && data?.payment?.status === 'successful') || (data?.status === 'paid' && data?.type === 'online') || (data?.status === 'paid' && data?.userRecivedCall) || (data?.status === 'paid' && data?.doctorCalling);

export const getStatusLogs = (data, status = 'complete') => (data?.length > 0 && data.filter(o => o.label === status).reverse().pop()) || { createdAt: null, label: status, note: '' };

export const getCaseType = data => (data?.caseType !== 'null' && typeof data?.caseType === 'string' && data?.caseType?.toLowerCase()) || '';

export const getPriceByDoctor = (doctorPriceByApps, doctorPrices, createByApp, consultLang = 'th') => {
  let total = 0;
  if (typeof doctorPriceByApps === 'object' && doctorPriceByApps?.length > 0) {
    const doctorPrice = doctorPriceByApps.find(item => item.appName === createByApp);
    if (doctorPrice?.telemed?.general[`skill_${consultLang}`]) {
      total = doctorPrice?.telemed?.general[`skill_${consultLang}`];
    } else if (doctorPrices?.telemed?.general[`skill_${consultLang}`]) {
      total = doctorPrices?.telemed?.general[`skill_${consultLang}`];
    }
  } else if (doctorPrices?.telemed?.general[`skill_${consultLang}`]) {
    total = doctorPrices?.telemed?.general[`skill_${consultLang}`];
  }
  return +total;
};

export const getDoctorDF = data => {
  const { payment } = data;
  let doctorPrice = 0;
  if (!data?.isDemo && payment?.status === 'successful' && (payment?.subtotal !== 'null' && payment?.subtotal !== 'NaN' && !Number.isNaN(payment.subtotal))) {
    if (data?.payDoctorAdjust && data?.payDoctorAdjust !== 'null' && !Number.isNaN(data?.payDoctorAdjust)) {
      doctorPrice = parseFloat(data.payDoctorAdjust);
    } else {
      doctorPrice = parseFloat(payment.subtotal);
      if (data?.doctorPrice !== 'null' && !Number.isNaN(data?.doctorPrice) && parseFloat(data?.doctorPrice) > 0) {
        doctorPrice = parseFloat(data?.doctorPrice);
      }
    }
  }
  return doctorPrice;
};

export const getDoctorRate = data => {
  let payDoctor = '';
  const { payDoctorType, payment } = data;
  if (!data?.isDemo && payment?.status === 'successful' && (payment?.subtotal !== 'null' && payment?.subtotal !== 'NaN' && !Number.isNaN(payment.subtotal))) {
    if (data?.payDoctorAdjust && data?.payDoctorAdjust !== 'null' && !Number.isNaN(data?.payDoctorAdjust)) {
      payDoctor = `Static: ${parseFloat(data.payDoctorAdjust)}`;
    } else {
      if (data?.payDoctor && !['', 'null'].includes(data?.payDoctor) && !Number.isNaN(data?.payDoctor)) {
        payDoctor = data?.payDoctor;
      }
      if (payDoctorType === 'rate' && parseFloat(payDoctor) > 0) {
        payDoctor = `Rate: ${parseFloat(payDoctor)}%`;
      } else if (payDoctorType === 'static' && parseFloat(payDoctor) > 0) {
        payDoctor = `Static: ${parseFloat(payDoctor)}`;
      } else if (getCaseType(data) === 'comercial') {
        payDoctor = `Rate: ${parseFloat(DEFAULT_DOCTOR_RATE)}%`;
      }
    }
  }
  return payDoctor;
};

export const getDoctorPrice = data => {
  let total = 0;
  const { payDoctorType, payment } = data;
  if (!data?.isDemo && payment?.status === 'successful' && (payment?.subtotal !== 'null' && payment?.subtotal !== 'NaN' && !Number.isNaN(payment.subtotal))) {
    if (data?.payDoctorAdjust && data?.payDoctorAdjust !== 'null' && !Number.isNaN(data?.payDoctorAdjust)) {
      return parseFloat(data.payDoctorAdjust);
    }
    let doctorPrice = parseFloat(payment.subtotal);
    if (data?.doctorPrice !== 'null' && !Number.isNaN(data?.doctorPrice) && parseFloat(data?.doctorPrice) > 0) {
      doctorPrice = parseFloat(data?.doctorPrice);
    } else if (data?.volunteerOnly) {
      return 0;
    }
    let payDoctor = 0;
    if (data?.payDoctor && !['', 'null'].includes(data?.payDoctor) && !Number.isNaN(data?.payDoctor)) {
      payDoctor = data?.payDoctor;
    }
    if (payDoctorType === 'rate' && parseFloat(payDoctor) > 0) {
      total = Math.ceil((parseFloat(doctorPrice) * parseFloat(payDoctor)) / 100);
    } else if (payDoctorType === 'static' && parseFloat(payDoctor) > 0) {
      total = parseFloat(payDoctor);
    } else if (getCaseType(data) === 'comercial') {
      total = Math.ceil((parseFloat(doctorPrice) * DEFAULT_DOCTOR_RATE) / 100);
    }
  }
  return total;
};

export const getCurrentAppToken = data => {
  const { patient } = data;
  const createByApp = (!['', 'null'].includes(data?.createByApp) && data?.createByApp) || (!['', 'null'].includes(patient?.appId) && patient?.appId);
  let currentToken = (!['', 'null'].includes(patient?.apnToken) && patient?.apnToken) || (!['', 'null'].includes(patient?.fcmToken) && patient?.fcmToken);
  if (data?.currentApnToken && !['', 'null'].includes(data?.currentApnToken)) {
    currentToken = data?.currentApnToken;
  }
  if (data?.currentFcmToken && !['', 'null'].includes(data?.currentFcmToken)) {
    currentToken = data?.currentFcmToken;
  }
  if (patient?.tokenByApp?.length > 0) {
    const tokenByApp = patient?.tokenByApp.find(o => o.appId === createByApp);
    if (tokenByApp?.apnToken && !['', 'null'].includes(tokenByApp?.apnToken)) {
      currentToken = tokenByApp?.apnToken;
    }
    if (tokenByApp?.fcmToken && !['', 'null'].includes(tokenByApp?.fcmToken)) {
      currentToken = tokenByApp?.fcmToken;
    }
  }
  return { currentToken, createByApp };
};

export const getDateTimeDifference = (startDateTime, endDateTime) => {
  const startTime = new Date(startDateTime).getTime();
  const endTime = new Date(endDateTime).getTime();
  const differenceInMilliseconds = endTime - startTime;
  return differenceInMilliseconds;
};

export const convertMilliseconds = milliseconds => {
  let timeMilliseconds = milliseconds;
  const oneSecondInMilliseconds = 1000;
  const oneMinuteInMilliseconds = oneSecondInMilliseconds * 60;
  const oneHourInMilliseconds = oneMinuteInMilliseconds * 60;
  const oneDayInMilliseconds = oneHourInMilliseconds * 24;
  const days = Math.floor(timeMilliseconds / oneDayInMilliseconds);
  timeMilliseconds %= oneDayInMilliseconds;
  const hours = Math.floor(timeMilliseconds / oneHourInMilliseconds);
  timeMilliseconds %= oneHourInMilliseconds;
  const minutes = Math.floor(timeMilliseconds / oneMinuteInMilliseconds);
  timeMilliseconds %= oneMinuteInMilliseconds;
  const seconds = Math.floor(timeMilliseconds / oneSecondInMilliseconds);
  return {
    days,
    hours,
    minutes,
    seconds
  };
};

export const checkAppointmentTime = booking => {
  let timeSlotRange = 15;
  if (booking?.information?.timeSlotRange) {
    timeSlotRange = booking.information.timeSlotRange;
  }
  const timeMilliseconds = timeSlotRange * 60 * 1000;
  const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
  const consultDate = moment(`${booking.information.visitDate} ${booking.information.visitTime}`).format('YYYY-MM-DD HH:mm:ss');
  const differenceInMilliseconds = getDateTimeDifference(currentDate, consultDate);
  if (differenceInMilliseconds < 0 && (differenceInMilliseconds + timeMilliseconds) > 0 && booking.status === 'paid') {
    return true;
  }
  return false;
};

export const isBrowserSupport = () => {
  const userAgent = navigator?.userAgent;
  let browserName;
  if (userAgent.match(/liff/i) || userAgent.match(/LIFF/i)) {
    browserName = 'liff';
    return { browser: browserName, support: false, agent: userAgent };
  }
  if (userAgent.match(/line/i) || userAgent.match(/Line/i) || userAgent.match(/LINE/i)) {
    browserName = 'line';
    return { browser: browserName, support: false, agent: userAgent };
  }
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
    return { browser: browserName, support: true, agent: userAgent };
  }
  if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
    return { browser: browserName, support: true, agent: userAgent };
  }
  if (userAgent.match(/safari/i)) {
    browserName = 'safari';
    return { browser: browserName, support: true, agent: userAgent };
  }
  if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
    return { browser: browserName, support: true, agent: userAgent };
  }
  if (userAgent.match(/edg/i)) {
    browserName = 'edge';
    return { browser: browserName, support: true, agent: userAgent };
  }
  browserName = 'no_defined';
  return { browser: browserName, support: false, agent: userAgent };
};

export const isAndroid = () => {
  if (/Android/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const isUseApp = data => data?.currentApnToken !== '' || data?.currentFcmToken !== '';

export const calPayDoctorRate = async (payDoctorRate, hospitals, refCompanyCode, apps) => {
  let value = PAY_DOCTOR_RATE;
  let label = 'Default Phatform';
  if (!['', 'null'].includes(refCompanyCode)) {
    const resCompany = await getCompanyByCode(refCompanyCode);
    if (resCompany?.data?.length > 0) {
      const company = resCompany?.data[0];
      if (company?.appForCompany?.value) {
        const appData = apps.find(o => o.value === company?.appForCompany?.value);
        if (appData && appData?.payDoctorRate !== 'null' && parseFloat(appData?.payDoctorRate) > 0) {
          value = parseFloat(appData?.payDoctorRate);
          label = `By Company (${company?.name})`;
        }
      }
    }
  }
  if (hospitals?.length > 0) {
    const hosData = await getHospitalByCode(hospitals[0]);
    if (hosData?.data?.length > 0) {
      const hospital = hosData?.data[0];
      if (hospital && hospital?.payDoctorRate !== 'null' && parseFloat(hospital.payDoctorRate) > 0) {
        value = parseFloat(hospital.payDoctorRate);
        label = `By Hospital (${hospital?.name})`;
      }
    }
  }
  if (payDoctorRate && payDoctorRate !== 'null') {
    value = parseFloat(payDoctorRate);
    label = 'By Doctor';
  }
  return {
    value,
    label
  };
};

export const getPayDoctorRate = async (payDoctorRate, hospitals, refCompanyCode, apps) => {
  const data = await calPayDoctorRate(payDoctorRate, hospitals, refCompanyCode, apps);
  return data?.value || 0;
};

export const displayMaskNumber = (value, mask = [3, 4, 4], prefix = '+') => {
  if (value && mask?.length > 0) {
    const newVal = value?.replace(prefix, '');
    const newRegEx = new RegExp(`^${mask.map(o => `(\\d{${o}})`).join('')}*`);
    const newOut = `${mask.map((o, index) => `$${index + 1}`).join('-')}`;
    return `${prefix}${newVal.replace(newRegEx, newOut)}`;
  }
  return '-';
};

export const copyToClipboard = value => {
  navigator.clipboard.writeText(value);
};

export const groupDoctor = () => {
  const groups = [];
  const lists = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  lists.forEach(g => {
    for (let i = 1; i < 10; i++) {
      groups.push({ value: `${g}${i}`, label: `${g}${i}` });
    }
  });
  return groups;
};

export const convertDateFormat = inputDate => {
  const thaiMonthsFull = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];
  const thaiMonthsShort = [
    'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
    'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
  ];

  let day;
  let month;
  let year;

  // Regular Expression รองรับรูปแบบ YYYY-MM-DD หรือ YYYY/MM/DD
  const standardDateFormatRegex = /^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12]\d|3[01])$/;

  if (standardDateFormatRegex.test(inputDate)) {
    // ถ้าเป็นรูปแบบ YYYY-MM-DD หรือ YYYY/MM/DD
    [year, month, day] = inputDate.split(/[-/]/).map(Number);
  } else {
    // ตรวจสอบรูปแบบที่มี '-' หรือ '/' พร้อมข้อความเดือน
    const dateWithSeparator = inputDate.match(/(\d{1,2})[-/](\d{1,2}|\D+)[-/](\d{4}|\d{2})/);
    if (dateWithSeparator) {
      day = parseInt(dateWithSeparator[1]);
      const monthInput = dateWithSeparator[2];
      year = parseInt(dateWithSeparator[3]);

      // แปลงปีสองหลักเป็นปี พ.ศ.
      if (year < 100) {
        year += 2400; // สมมติว่าทุกปีสองหลักเป็น พ.ศ.
      }

      // แปลงปี พ.ศ. เป็น ค.ศ.
      if (year > 2400) year -= 543;

      // แปลงเดือนที่เป็นข้อความไทยเป็นตัวเลข
      const numericMonth = Number(monthInput); // แปลงเป็นตัวเลข
      if (Number.isNaN(numericMonth)) {
        if (thaiMonthsFull.includes(monthInput)) {
          month = thaiMonthsFull.indexOf(monthInput) + 1;
        } else if (thaiMonthsShort.includes(monthInput)) {
          month = thaiMonthsShort.indexOf(monthInput) + 1;
        } else {
          return '';
        }
      } else {
        month = numericMonth;
      }
    } else {
      return '';
    }
  }

  // ตรวจสอบความถูกต้องของวันที่
  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return '';
  }

  // คืนค่าในรูปแบบ YYYY-MM-DD
  return `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // เพิ่ม leading zero ถ้าเดือนน้อยกว่า 10
  const day = currentDate.getDate().toString().padStart(2, '0'); // เพิ่ม leading zero ถ้าวันน้อยกว่า 10
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const chkValidEKyc = o => (((typeof o?.ekycError === 'string' && ['', 'null'].includes(o?.ekycError.toLowerCase())) || (typeof o?.ekycError === 'object' && Object.keys(o?.ekycError).length <= 0)));
export const chkValidApproveEKyc = o => (((typeof o?.ekycError === 'string' && !['', 'null', 'create by admin', 'import by admin', 'partner approved'].includes(o?.ekycError.toLowerCase()))));
export const chkValidAdminEKyc = o => ((typeof o?.ekycError === 'string' && ['create by admin', 'import by admin', 'partner approved'].includes(o?.ekycError.toLowerCase())));

export const checkStepEkyc = (o, width = 24) => {
  if (o?.ekyc && chkValidEKyc(o) && o?.passportNumber && o?.passportNumber !== 'null' && o?.passportNumberDocument?.id) {
    return <Icon className="my-auto text-success" icon="ion:checkmark-done-circle" width={width} />;
  }
  if (o?.ekyc && chkValidAdminEKyc(o)) {
    return <Icon className="my-auto text-success" icon="material-symbols:admin-panel-settings" width={width} />;
  }
  if (o?.passportNumber && o?.passportNumber !== 'null' && o?.passportNumberDocument?.id && !chkValidAdminEKyc(o)) {
    return <Icon className="my-auto text-warning" icon="material-symbols:rate-review-rounded" width={width} />;
  }
  return <Icon className="my-auto text-light-gray" icon="ion:checkmark-done-circle" width={width} />;
};

export const checkStepEkycText = (o, t) => {
  if (o?.ekyc && chkValidEKyc(o) && o?.passportNumber && o?.passportNumber !== 'null' && o?.passportNumberDocument?.id) {
    return <span className="text-success">{t('Identity verified')}</span>;
  }
  if (o?.ekyc && chkValidAdminEKyc(o)) {
    return <span className="text-success">{t('Identity verified')}</span>;
  }
  if (o?.passportNumber && o?.passportNumber !== 'null' && o?.passportNumberDocument?.id && !chkValidAdminEKyc(o)) {
    return <span className="text-danger">{t('Waiting for data verification')}</span>;
  }
  return <span className="text-dark-gray">{t('Not verified')}</span>;
};

export const convertThaiNumberToArabic = number => {
  const numbers = number.split('');
  const listNumbers = [];
  numbers.map(arg => {
    if (arg === '๑') {
      listNumbers.push(arg);
    } else if (arg === '๒') {
      listNumbers.push(arg);
    } else if (arg === '๓') {
      listNumbers.push(arg);
    } else if (arg === '๔') {
      listNumbers.push(arg);
    } else if (arg === '๕') {
      listNumbers.push(arg);
    } else if (arg === '๖') {
      listNumbers.push(arg);
    } else if (arg === '๗') {
      listNumbers.push(arg);
    } else if (arg === '๘') {
      listNumbers.push(arg);
    } else if (arg === '๙') {
      listNumbers.push(arg);
    } else {
      listNumbers.push(arg);
    }
    return arg;
  });
  if (listNumbers.length > 0) {
    return listNumbers.join('');
  }
  if (numbers.length > 0) {
    return number.split('').filter(item => item.trim() !== '').join('');
  }
  return number.split('').filter(item => item.trim() !== '').join('');
};

export const isThaiNumber = text => {
  if (text) {
    return true;
  }
  return true;
};

export const getProvineFromAddressComponents = components => {
  let name = '';
  if (components && components.length > 0) {
    components.map(arg => {
      if (typeof arg.types !== 'undefined') {
        if (arg.types.includes('administrative_area_level_1')) {
          name = arg.long_name;
        }
      }
      return arg;
    });
  }
  return name;
};

export const getDistrictFromAddressComponents = components => {
  let name = '';
  if (components && components.length > 0) {
    components.map(arg => {
      if (typeof arg.types !== 'undefined') {
        if ((arg.types.includes('sublocality') && arg.types.includes('sublocality_level_1') && arg.types.includes('political')) || (arg.types.includes('administrative_area_level_2') && arg.types.includes('political'))) {
          name = arg.long_name;
        }
      }
      return arg;
    });
  }
  return name;
};

export const getSubDistrictFromAddressComponents = components => {
  let name = '';
  if (components && components.length > 0) {
    components.map(arg => {
      if (typeof arg.types !== 'undefined') {
        if ((arg.types.includes('sublocality') && arg.types.includes('sublocality_level_2')) || (arg.types.includes('sublocality') && arg.types.includes('sublocality_level_1') && arg.types.includes('political'))) {
          name = arg.long_name;
        }
      }
      return arg;
    });
  }
  return name;
};

export const getPostalCodeFromAddressComponents = components => {
  let name = '';
  if (components && components.length > 0) {
    components.map(arg => {
      if (typeof arg.types !== 'undefined') {
        if (arg.types.includes('postal_code') && parseInt(arg.long_name) > 10000 && parseInt(arg.long_name) < 99999) {
          name = arg.long_name;
        }
      }
      return arg;
    });
  }
  return name;
};

export const getSubAddress = (dataSet, types) => {
  let name = '-';
  if (dataSet?.length) {
    types.forEach(type => {
      const response = dataSet.filter(item => item.types.includes(type));
      if (response.length > 0 && name === '-') {
        name = response[0].long_name;
      }
      name = name === '-' && type === 'postal_code' ? '00000' : name;
    });
  }
  return name;
};

export const getAddress = dataSet => {
  const arrAddress = [];
  if (dataSet?.length) {
    const streetNumber = getSubAddress(dataSet, ['street_number']);
    const premise = getSubAddress(dataSet, ['premise']);
    const route = getSubAddress(dataSet, ['route']);
    if (streetNumber !== '-') arrAddress.push(streetNumber);
    if (premise !== '-') arrAddress.push(premise);
    if (route !== '-') arrAddress.push(route);
  }
  return arrAddress.join(' ');
};

export const getFullAddress = dataSet => {
  const arrAddress = [];
  if (dataSet?.length) {
    arrAddress.push(getSubAddress(dataSet, ['locality', 'sublocality_level_2']));
    arrAddress.push(getSubAddress(dataSet, ['administrative_area_level_2', 'sublocality_level_1']));
    arrAddress.push(getSubAddress(dataSet, ['administrative_area_level_1']));
    arrAddress.push(getSubAddress(dataSet, ['postal_code']));
  }
  return arrAddress.join(' ');
};

export const getDatetimeFromVisit = (info, format = 'D MMMM YYYY HH:mm', isConverYearTh = true) => {
  if (info?.visitDate) {
    const datetime = `${info?.visitDate} 00:00`;
    return getDatetimeLocal(datetime, format, false, isConverYearTh);
  }
  return '-';
};

export const checkDazAdmin = auth => {
  if ((DAZ_CODE === auth?.profile?.refCompanyCode || auth?.profile?.refCompanyCode === '' || auth?.profile?.refCompanyCode === 'null' || (auth?.profile?.refsCompanyCode && DAZ_CODE === auth?.profile?.refsCompanyCode[0]))) {
    return false;
  }
  return true;
};

export const checkCouponUsedBySaaS = coupon => {
  if (coupon) {
    return true;
  }
  return false;
};

export const byteSize = str => new Blob([str]).size;
